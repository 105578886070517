import React from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';

function encode(data) {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

export default class ContactForm extends React.Component {
  constructor(props) {
      super(props);
      this.state = {};
  }

  handleChange = (e) => {
      this.setState({[e.target.name]: e.target.value});
  }

  handleCheckboxChange = (e) => {
      var isChecked = $(e.target).is(':checked');
      if(isChecked){
          this.setState({[e.target.name]: 1});
      }else{
          this.setState({[e.target.name]: 0});
      }
  }

  handleSubmit = e => {
      // check required
      if(!this.state.email || !this.state.name){
          alert('Bitte geben Sie Namen und E-Mail Adresse an.');
          return;
      }

      // check regex
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!re.test(this.state.email.toLowerCase())){
          alert("Mit der E-Mail Adresse stimmt etwas nicht.");
          return;
      }

      // send form
      fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", ...this.state })
      }).then(() => {
          // success message
          alert('Vielen Dank!');
          // change DOM
          var $btn = $(ReactDOM.findDOMNode(this)).find('button');
          $btn.attr('disabled', 'disabled');
          $btn.addClass('disabled');
          $btn.html('Abgeschickt');
      }).catch(error => alert(error));

      //
      e.preventDefault();
  };

  render() {
    return (
        <form
          name="contact"
          className="contact-form reveal"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <p hidden>
              Don’t fill this out: <input name="bot-field" />
          </p>
          <div className="contact-form-first-row">
          <div>
              <input className="text" type="text" name="name" onChange={this.handleChange} placeholder="Name" required/>
          </div>
          <div>
              <input className="text" type="email" name="email" onChange={this.handleChange} placeholder="E-Mail (damit ich Ihnen antworten kann)" required/>
          </div>
          </div>
          <div className="contact-form-second-row">
              <textarea className="textarea" name="message" onChange={this.handleChange} placeholder="Nachricht" required>
              </textarea>
          </div>
          <div className="contact-form-third-row">
            <button type="Absenden">{this.props.submitText}</button>
          </div>
        </form>
    );
  }
}