import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Logo from '../../components/Logo'
import Layout from '../../components/Layout'
import Navigation from '../../components/NavigationWithLogo'
import '../../style/all.css'
import ContactForm from '../../components/ContactForm'

class Kontakt extends React.Component {
  render() {
    const post = get(this, 'props.data.allContentfulHomepage.nodes')[0]
    return (
      <Layout>
        <Logo />
        <Navigation menuText="Kontakt" />

        <div>
          <Helmet title={`Kontakt | Adrienn Bazsó`}>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, viewport-fit=cover"
            />
          </Helmet>
          <section className="section-kontakt">
            <div className="content">
              {/* <h1>Kontakt</h1> */}
              <div className="section-content">
                <div className="list-entry">
                  <div
                    className="blog-content"
                    dangerouslySetInnerHTML={{ __html: post.kontakt.childMarkdownRemark.html }}
                  />
                  <ContactForm submitText="Absenden" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Kontakt

export const Query = graphql`
  query KontaktQuery {
    allContentfulHomepage {
      nodes {
        kontakt {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
